import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ slugLine, backLink, cv }) => (
  <>
    {backLink && <Link class="article-back-link -top" to={backLink.path}>Return to {backLink.name}</Link>}
    <header className={`header${backLink ? ' -has-back-link' : ''} ${cv ? ' -cv' : ''}`}>
      <h1>Coleman Collins</h1>
      <h4>{slugLine}</h4>
      <nav>
        {false &&
        <ul>
          <li><Link to="/writing">Writing</Link></li>
          <li><Link to="/work">Work</Link></li>
          <li><Link to="/work">Now</Link></li>
          <li><Link to="/etc">Etc.</Link></li>
        </ul>
        }
      </nav>
    </header>
  </>
)

Header.propTypes = {
  slugLine: PropTypes.string,
}

Header.defaultProps = {
  slugLine: `is a designer, among other things`,
}

export default Header
