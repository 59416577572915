import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ slugLine, children, backLink, cv }) => {
  return (
    <>
      <Header slugLine={slugLine} backLink={backLink} cv={cv} />
        <>{children}</>
        {backLink && <Link class={`article-back-link -bottom`} to={backLink.path}>Return to {backLink.name}</Link>}
        <footer className="footer">
          <div className={`footer-content${cv ? ' cv-footer': ''}`}>
            handmade by Coleman Collins | © {new Date().getFullYear()} | <a class="footer-link" href="mailto:hello@colemancollins.com" target="_blank" rel="noreferrer">hello@colemancollins.com</a>
          </div>
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  slugLine: PropTypes.string
}

export default Layout
